export const COMPANY_NAME = 'Splenz Pharmaceuticals Pvt. Ltd.';

export const COMPANY_CONTACT_EMAIL = 'contact@splenzpharma.com';
export const COMPANY_CONTACT_EMAIL_LINK = 'mailto:info@wellonapharma.com'
export const COMPANY_SERVICE_EMAIL = 'atyourservice@splenzpharma.com';

export const COMPANY_MOBILE = '+91-9766772587';
export const COMPANY_ALTERNATE_MOBILE = '+91-9766404022';

export const COMPANY_HEADQUARTER_ADDRESS = 'Splenz Pharmaceuticals Pvt. Ltd. Survey No. 1095, Milkat. No. 3672, Near Sakal Press, Trimurti Nagar, Shiv Road, Wadki, Pune-412308';
export const COMPANY_HEADQUARTER_ADDRESS_LINK = 'https://www.google.com/maps/place/Splenz+Pharmaceuticals+Pvt.+Ltd./@18.445702,73.968999,12z/data=!4m6!3m5!1s0x3bc2e947315b181b:0x84eb926079c7db6a!8m2!3d18.4457018!4d73.9689988!16s%2Fg%2F11tfwqxm6j?hl=en-US'

export const COMPANY_COPYRIGHT = 'Copyright © 2023-2024 Splenz Pharmaceuticals Pvt. Ltd. All Rights Reserved.';
