import { Box, Modal, TextField } from "@mui/material";
import "../../styles/floating-modal-button.css";
import { useState } from "react";
import SpText from "./sp-text";
import { BLACK, PINK, WHITE } from "../../utils/colors";
import CloseIcon from "@mui/icons-material/Close";
import SpButton from "./sp-button";

const FloatingModalButton = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const CallbackModal = () => {
    return (
      <div>
        <Modal open={open} onClose={handleClose}>
          <Box
            className="modal-style"
            sx={{ width: { xs: "90%", sm: "80%", lg: "70%", xl: "60%" } }}
          >
            <Box
              sx={{
                display: "flex",
                py: 2,
                backgroundColor: BLACK,
                pl: 2,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <SpText
                label="Request A Call Back"
                variant="h5"
                isBold
                sx={{ color: WHITE }}
              />
              <CloseIcon
                sx={{ color: WHITE, pr: 2, cursor: "pointer" }}
                onClick={handleClose}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                mx: {
                  xs: 1,
                  sm: 2,
                  lg: 3.2,
                  xl: 3.5,
                },
                my: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    lg: "row",
                    xl: "row",
                  },
                  width: "95%",
                  mt: 2,
                }}
              >
                <TextField fullWidth required label="Name" variant="outlined" />
                <TextField
                  fullWidth
                  required
                  label="Email"
                  variant="outlined"
                  type="email"
                  sx={{
                    ml: {
                      xs: 0,
                      sm: 2,
                      lg: 2,
                      xl: 2,
                    },
                    mt: {
                      xs: 2,
                      sm: 0,
                      lg: 0,
                      xl: 0,
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    lg: "row",
                    xl: "row",
                  },
                  width: "95%",
                  mt: 2,
                }}
              >
                <TextField
                  fullWidth
                  required
                  label="Phone no"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  required
                  label="Country"
                  variant="outlined"
                  type="email"
                  sx={{
                    ml: {
                      xs: 0,
                      sm: 2,
                      lg: 2,
                      xl: 2,
                    },
                    mt: {
                      xs: 2,
                      sm: 0,
                      lg: 0,
                      xl: 0,
                    },
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    lg: "row",
                    xl: "row",
                  },
                  width: "95%",
                  mt: 2,
                }}
              >
                <TextField
                  fullWidth
                  label="Leave Message"
                  variant="outlined"
                  multiline
                  rows={4}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    lg: "row",
                    xl: "row",
                  },
                  width: "95%",
                  mt: 2,
                }}
              >
                <SpButton
                  label="SUBMIT"
                  sx={{
                    backgroundColor: PINK,
                    "&:hover": { backgroundColor: BLACK },
                    width: "20vh",
                    display: "flex",
                    mt: 2,
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    );
  };

  return (
    <Box sx={{ display: { xs: "none", sm: "flex", lg: "flex", xl: "flex" } }}>
      <button className="floating-modal-button" onClick={handleOpen}>
        Request A Call Back
      </button>
      <CallbackModal />
    </Box>
  );
};

export default FloatingModalButton;
