import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import SpText from "./common/sp-text";
import { PINK } from "../utils/colors";
import "../styles/auto-slider.css";

// Define types for slide data
interface SlideData {
  banner: string;
  title: string;
  subtitle: string;
  description: string;
}

interface AutoSliderProps {
  slides: SlideData[];
}

const delay = 8000;

const AutoSlider: React.FC<AutoSliderProps> = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, delay);
    return () => clearInterval(interval);
  }, [slides.length]);

  // Render each slide content
  const renderSlideContent = useCallback(
    (data: SlideData) => {
      const { banner, title, subtitle, description } = data;
      return (
        <Box
          sx={{
            textAlign: "center",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Box
            component="img"
            src={banner}
            alt={subtitle}
            sx={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: { xs: "none", sm: "none", lg: "flex", xl: "flex" },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: "#000",
              backgroundColor: "rgba(255, 255, 255, 0)",
              width: "40%",
              ml: 10,
              whiteSpace: "wrap",
            }}
          >
            <SpText label={title} variant="h5" isBold />
            <SpText
              label={subtitle}
              variant="h4"
              isBold
              sx={{
                color: PINK,
                fontWeight: "bold",
                margin: "10px 0",
                maxWidth: "600px",
              }}
            />
            <SpText label={description} variant="h6" sx={{ maxWidth: "600px" }} />
          </Box>
        </Box>
      );
    },
    []
  );

  return (
    <Box className="slideshow">
      <Box
        className="slideshowSlider"
        style={{ transform: `translate3d(${-currentIndex * 100}%, 0, 0)` }}
      >
        {slides.map((slide, index) => (
          <Box className="slide" key={`slide_view_${index}`}>
            {renderSlideContent(slide)}
          </Box>
        ))}
      </Box>

      <Box className="slideshowDots">
        {slides.map((_, idx) => (
          <Box
            key={`dot_view_${idx}`}
            className={`slideshowDot${currentIndex === idx ? " active" : ""}`}
            onClick={() => setCurrentIndex(idx)}
          ></Box>
        ))}
      </Box>
    </Box>
  );
};

export default AutoSlider;
