import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { BLACK, PINK, WHITE } from "../../utils/colors";
import SpText from "../../components/common/sp-text";
import GastroentrologyImage from "../../assets/products/category/gastroenterology.jpg";
import UrologyImage from "../../assets/products/category/urology.jpg";
import GynaecologyImage from "../../assets/products/category/gynaecology.jpg";
import NephrologyImage from "../../assets/products/category/nephrology.jpg";
import { useNavigate } from "react-router-dom";
import { snakeCase } from "lodash";
import "../../styles/scroll-view-style.css";
import { gastroenterologyProductsDetails } from "../../constants/products/gastroenterology";
import { urologyProductsDetails } from "../../constants/products/urology";
import { gynaecologyProductsDetails } from "../../constants/products/gynaecology";
import SpButton from "../../components/common/sp-button";
import {
  OUR_SERVICE_QUOTE_1,
  OUR_SERVICE_QUOTE_2,
  WELCOME_QUOTE,
} from "../../utils/en-strings";
import QualityProductIcon from "../../assets/home/contract-manufacturing.jpg";
import ManagementIcon from "../../assets/home/3rd-party-manufacturing.jpg";
import MarketingInputIcon from "../../assets/home/generic-product.jpg";
import PharmaFranchiseIcon from "../../assets/home/loan-license.jpg";
import ApiIcon from "@mui/icons-material/Api";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import HistoryIcon from "@mui/icons-material/History";
import PublicIcon from "@mui/icons-material/Public";
import GroupsIcon from "@mui/icons-material/Groups";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import MapImage from "../../assets/app-images/map.jpg";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { useEffect, useState } from "react";
import DedicatedImage from "../../assets/home/we-are-dedicated.jpg";
import QualityPolicyImage from "../../assets/app-images/quality-policy.jpg";
import Mission2Image from "../../assets/app-images/mission-vision/mission2.jpg";
import Vision2Image from "../../assets/app-images/mission-vision/vision2.jpg";
import Management2Image from "../../assets/app-images/mission-vision/management2.jpg";
import Banner1 from "../../assets/home/banner1.jpg";
import Banner2 from "../../assets/home/banner2.jpg";
import AutoSlider from "../../components/auto-slider";
import { nephrologyProductsDetails } from "../../constants/products/nephrology";

const ProductCategory = [
  {
    id: 1,
    name: "Gastroenterology",
    image: GastroentrologyImage,
  },
  {
    id: 2,
    name: "Urology",
    image: UrologyImage,
  },
  {
    id: 3,
    name: "Nephrology",
    image: NephrologyImage,
  },
  {
    id: 4,
    name: "Gynaecology",
    image: GynaecologyImage,
  }
];

const companyServices = [
  {
    id: 1,
    name: "Quality Products",
    route: "/quality-policy",
    image: QualityProductIcon,
  },
  {
    id: 2,
    name: "Management & personnel",
    route: "/management",
    image: ManagementIcon,
  },
  {
    id: 3,
    name: "Marketing Inputs",
    route: "/mission-vision",
    image: MarketingInputIcon,
  },
  {
    id: 4,
    name: "Pharma Franchise",
    route: "/why-us",
    image: PharmaFranchiseIcon,
  },
];

const whyUsLeftStrings = [
  "Government Recognised Export House",
  "75 Years of Experience",
  "Research based products",
  "Modern Infrastructure",
];

const whyUsRightStrings = [
  "Competitive Pricing",
  "Short Delivery Lead Time",
  "Efficient Communication",
  "Offering Customization",
];

const whyUsCompanyData = [
  {
    id: 1,
    value: "40 +",
    field: "Countries",
    image: <PublicIcon fontSize="large" />,
  },
  {
    id: 2,
    value: "1250+",
    field: "Happy Clients",
    image: <GroupsIcon fontSize="large" />,
  },
  {
    id: 3,
    value: "600+",
    field: "Products",
    image: <VaccinesIcon fontSize="large" />,
  },
  {
    id: 4,
    value: "10+",
    field: "Achievements",
    image: <WorkspacePremiumIcon fontSize="large" />,
  },
];

const globalPresence = [
  {
    name: "Asia",
    description:
      "Afghanistan, Israel, Jordan, Pakistan, Russia, Saudi Arabia, Singapore, Syria, Philippines, Turkey, Turkmenistan, Uzbekistan, Yemen, Vietnam, Indonesia, Malaysia, Iran, Tajikistan, etc.",
  },
  {
    name: "Africa",
    description:
      "Africa: South Africa, Uganda, Nigeria, Ghana, Kenya, Mauritius, Botswana, Egypt, Ethiopia, Libya, Sudan,Congo, Gambia, Ivory Coast , etc.",
  },
  {
    name: "North & South America",
    description:
      "USA, Canada, Panama, Trinidad and Tobago, Costa Rica, Puerto Rico, Dominican Republic, Argentina, Guatemala, Guyana, Peru, Bolivia, Venezuela , Brazil, Bahamas, Ecuador, etc.",
  },
  {
    name: "Europe",
    description:
      "Austria , Azerbaijan, Belarus, Belgium, Cyprus, Czech Republic, Denmark, France, Georgia, Germany, Hungary, Ireland, Iceland, Netherlands, Norway, Poland, Spain, Sweden, Switzerland, Ukraine, United Kingdom (UK) , Latvia, etc.",
  },
  {
    name: "OCEANIA",
    description: "Australia, New Zealand, Fiji, etc.",
  },
];

const dedicatedArray = [
  {
    name: "Mission",
    description:
      "Splenz mission is to uphold our social responsibility by improving the Quality of human life by providing Quality Products of International Standards and ensuring premier Health Care for the society. They endeavor to utilize their full potential by delivering balanced and improved Product Portfolio with Best Quality at Optimum Price. Splenz envisages of being a forerunner in the most emerging and ever growing Pharmaceutical Market.",
    readMoreRoute: "/mission-vision",
    image: Mission2Image,
  },
  {
    name: "Vision",
    description:
      "As an innovative, dynamic and reliable Pharmaceutical company, our vision is to stand out as exemplary performer in national as well as international market. Splenz wants to excel in manufacturing Highest Quality Products with ethics and obliged to do right and fair business practices.",
    readMoreRoute: "/mission-vision",
    image: Vision2Image,
  },
  {
    name: "Management’s Message",
    description:
      "One of our core values - ‘We are a team. Everyone matters.’ - has been the mantra that has taken Splenz Pharmaceuticals to such heights. Hence, we have cultivated a work culture that permits our employees to learn, innovate and experiment with new ideas.",
    readMoreRoute: "/management",
    image: Management2Image,
  },
  {
    name: "Quality policy",
    description:
      "Our quality policy stands committed to maintaining the good manufacturing practices, conform to the International standards and ensure a timely supply of goods with cost effectiveness. We believe in achieving the highest level of customer satisfaction and market leadership in domestic, as well as overseas markets, by implementing the Quality Management Systems (QMS) on a continual basis.",
    readMoreRoute: "quality-policy",
    image: QualityPolicyImage,
  },
];

const allProductsDetails = [
  ...gastroenterologyProductsDetails,
  ...urologyProductsDetails,
  ...gynaecologyProductsDetails,
].filter((product) => product.newlyAdded);

const slides = [
  {
    banner: Banner1,
    title: "CARE WITH QUALITY",
    subtitle: "WELCOME TO SPLENZ PHARMACEUTICALS PVT. LTD.",
    description:
      "WHO-GMP | USFDA | ISO 9001:2015 Certified Pharmaceuticals",
  },
  {
    banner: Banner2,
    title: "CARE WITH QUALITY",
    subtitle: "MANAGEMENT OF SPLENZ",
    description: "Splenz believes it has a responsibility towards the society.",
  },
];

const HomePageView = () => {
  const navigate = useNavigate();
  const [globalExpanded, setGlobalExpanded] = useState(0);
  const [dedicatedExpanded, setDedicatedExpanded] = useState(0);

  const [currentIndex, setCurrentIndex] = useState(0);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  //   }, 10000); // Change every 10 seconds

  //   return () => clearInterval(intervalId); // Cleanup on component unmount
  // }, [slides.length]);

  const handleGlobalChange = (panel: any) => (event: any, isExpanded: any) => {
    setGlobalExpanded(isExpanded ? panel : null);
  };

  const handleDedicatedChange =
    (panel: any) => (event: any, isExpanded: any) => {
      setDedicatedExpanded(isExpanded ? panel : null);
    };

  const SplenzPharmaProductView = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            ml: 4,
            mt: 3,
            justifyContent: "center",
          }}
        >
          <SpText label="Splenz Pharma In Super Specialities" variant="h4" isBold />
        </Box>
        <Box className="image-grid" sx={{ mx: 2 }}>
          {ProductCategory.map((productObj: any, index: number) => (
            <div
              key={index}
              className="image-item"
              style={{
                backgroundColor: WHITE,
                boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)",
                padding: 20,
                borderRadius: "20px",
                cursor: "pointer",
                maxWidth: "450px",
              }}
              onClick={() => navigate(`./${snakeCase(productObj.name)}`)}
            >
              <img src={productObj.image} alt={`img-${index}`} />
              <Box className="text">
                <SpText
                  label={productObj.name}
                  variant="h4"
                  isBold
                  sx={{ mt: 2 }}
                  onClick={() => navigate(`./${snakeCase(productObj.name)}`)}
                />
              </Box>
            </div>
          ))}
        </Box>
      </>
    );
  };

  const WelcomeView = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#c8c8c6",
          px: 2,
          py: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                ml: 4,
                justifyContent: "left",
              }}
            >
              <SpText
                label="Welcome to Splenz Pharmaceuticals Pvt. Ltd."
                variant="h5"
                isBold
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                ml: { xs: 1, sm: 1, lg: 4, xl: 4 },
              }}
            >
              <SpText
                label={WELCOME_QUOTE}
                variant="body1"
                sx={{ mt: 2, textAlign: "justify" }}
              />
              <Typography
                className="text-link"
                variant="body2"
                sx={{ textAlign: "justify", color: PINK }}
                onClick={() => navigate("/about-us")}
              >
                Read More...
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", sm: "100%", lg: "50%", xl: "50%" },
              backgroundColor: BLACK,
              color: WHITE,
              justifyContent: "center",
              alignItems: "center",
              py: 2,
              my: 2,
              ml: { xs: 0, sm: 0, lg: 4, xl: 4 },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <SpText
                label="Get Business Quote"
                variant="h4"
                isBold
                sx={{ mt: 2 }}
              />
              <SpText
                label="In Less than 30 Minutes !"
                variant="h6"
                sx={{ mt: 2 }}
              />
            </Box>
            <SpButton
              label="REQUEST A QUOTE"
              sx={{
                backgroundColor: PINK,
                "&:hover": { backgroundColor: "blue" },
                width: "28vh",
                display: "flex",
                mt: 2,
              }}
              onClick={() => navigate("/contact-us")}
            />
          </Box>
        </Box>
        <Box className="scroll-container">
          {allProductsDetails.map((productObj, index) => (
            <Box
              key={index}
              className="scroll-item"
              sx={{ px: 2, py: 2, backgroundColor: WHITE }}
            >
              <img
                src={productObj.image}
                alt={`img-${index}`}
                style={{ maxWidth: "200px" }}
              />
            </Box>
          ))}
          <Box
            className="scroll-item"
            sx={{ px: 2, py: 2, backgroundColor: BLACK }}
          >
            <SpButton
              label="View All"
              sx={{
                backgroundColor: PINK,
                "&:hover": { backgroundColor: "blue" },
                width: "20vh",
                display: "flex",
                mt: 2,
              }}
              onClick={() => navigate("/gallery")}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const OurServicesView = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            ml: 4,
            my: 4,
            justifyContent: "center",
          }}
        >
          <SpText label="Our Services" variant="h5" isBold />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mx: { xs: 2, sm: 2, lg: 4, xl: 4 },
              }}
            >
              <SpText
                label={OUR_SERVICE_QUOTE_1}
                variant="body1"
                sx={{ mt: 2, textAlign: "justify" }}
              />
              <SpText
                label={OUR_SERVICE_QUOTE_2}
                variant="body1"
                sx={{ mt: 2, textAlign: "justify" }}
              />
              <Typography
                className="text-link"
                variant="body2"
                sx={{ textAlign: "justify", color: PINK }}
                onClick={() => navigate("/about-us")}
              >
                Read More...
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column-reverse",
                  sm: "column-reverse",
                  lg: "row",
                  xl: "row",
                },
                alignItems: "center",
                backgroundColor: PINK,
                padding: 2,
                borderRadius: 2,
                boxShadow: 1,
                color: WHITE,
                mt: 3,
                mx: { xs: 2, sm: 2, lg: 4, xl: 4 },
              }}
            >
              <SpButton
                label="Request a Quote"
                sx={{
                  backgroundColor: BLACK,
                  "&:hover": { backgroundColor: "blue" },
                  color: "white",
                  padding: "12px 24px",
                  borderRadius: {
                    xs: 0,
                    sm: 0,
                    lg: "0 30px 30px 0",
                    xl: "0 30px 30px 0",
                  },
                  textTransform: "none",
                  fontWeight: "bold",
                  fontSize: "16px",
                  mt: { xs: 3, sm: 3, lg: 0, xl: 0 },
                }}
                onClick={() => navigate("/contact-us")}
              />
              <Box sx={{ display: "flex", flexDirection: "column", ml: 2 }}>
                <SpText
                  label="Get Business Quote In Less than"
                  variant="h6"
                  isBold
                  sx={{ textAlign: "left" }}
                />
                <SpText
                  label="30 Minutes!"
                  variant="h4"
                  isBold
                  sx={{ textAlign: "left" }}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Grid container spacing={4} sx={{ padding: 1.5 }}>
              {companyServices.map((serviceObj, index) => (
                <Grid key={index} item xs={12} md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      padding: 3,
                      backgroundColor: "white",
                      borderRadius: 2,
                      boxShadow: 1,
                      height: 100,
                    }}
                  >
                    <img
                      src={serviceObj.image}
                      alt={`img-${index}`}
                      width={50}
                    />
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{ ml: 2, cursor: "pointer" }}
                      onClick={() => navigate(serviceObj.route)}
                    >
                      {serviceObj.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </>
    );
  };

  const CompleteRangeView = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#c8c8c6",
          px: 2,
          py: 2,
          mt: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            ml: 4,
            my: 3,
            justifyContent: "center",
          }}
        >
          <SpText label="Complete Range of Pharmaceuticals" variant="h5" isBold />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
            justifyContent: "space-evenly",
            alignContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              ml: 5,
            }}
          >
            <SpText
              label="Gastroenterology"
              variant="h5"
              isBold
              sx={{ color: PINK }}
            />
            <List>
              <ListItem alignItems="flex-start">
                <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
                  {gastroenterologyProductsDetails.map((productDetail, index) => (
                    <Box key={index} sx={{ display: "flex", flexDirection: "row" }}>
                      <ApiIcon sx={{ mr: 1.5 }} />
                      <Typography
                        className="text-link"
                        variant="subtitle1"
                        onClick={() =>
                          navigate(
                            `./gastroenterology/${snakeCase(
                              productDetail.name
                            )}?id=${productDetail.id}`
                          )
                        }
                        sx={{ color: BLACK, fontWeight: "bold" }}
                      >
                        {productDetail.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </ListItem>
            </List>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              ml: { xs: 5, sm: 5, lg: 0, xl: 0 },
            }}
          >
            <SpText label="Urology" variant="h5" isBold sx={{ color: PINK }} />
            <List>
              <ListItem alignItems="flex-start">
                <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
                  {urologyProductsDetails.map((productDetail, index) => (
                    <Box key={index} sx={{ display: "flex", flexDirection: "row" }}>
                      <ApiIcon sx={{ mr: 1.5 }} />
                      <Typography
                        className="text-link"
                        variant="subtitle1"
                        onClick={() =>
                          navigate(
                            `./urology/${snakeCase(productDetail.name)}?id=${
                              productDetail.id
                            }`
                          )
                        }
                        sx={{ color: BLACK, fontWeight: "bold" }}
                      >
                        {productDetail.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </ListItem>
            </List>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              ml: 5,
            }}
          >
            <SpText
              label="Nephrology"
              variant="h5"
              isBold
              sx={{ color: PINK }}
            />
            <List>
              <ListItem alignItems="flex-start">
                <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
                  {nephrologyProductsDetails.map((productDetail, index) => (
                    <Box key={index} sx={{ display: "flex", flexDirection: "row" }}>
                      <ApiIcon sx={{ mr: 1.5 }} />
                      <Typography
                        className="text-link"
                        variant="subtitle1"
                        onClick={() =>
                          navigate(
                            `./Nephrology/${snakeCase(
                              productDetail.name
                            )}?id=${productDetail.id}`
                          )
                        }
                        sx={{ color: BLACK, fontWeight: "bold" }}
                      >
                        {productDetail.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </ListItem>
            </List>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              ml: 5,
            }}
          >
            <SpText
              label="Gynaecology"
              variant="h5"
              isBold
              sx={{ color: PINK }}
            />
            <List>
              <ListItem alignItems="flex-start">
                <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
                  {gynaecologyProductsDetails.map((productDetail, index) => (
                    <Box key={index} sx={{ display: "flex", flexDirection: "row" }}>
                      <ApiIcon sx={{ mr: 1.5 }} />
                      <Typography
                        className="text-link"
                        variant="subtitle1"
                        onClick={() =>
                          navigate(
                            `./gastroenterology/${snakeCase(
                              productDetail.name
                            )}?id=${productDetail.id}`
                          )
                        }
                        sx={{ color: BLACK, fontWeight: "bold" }}
                      >
                        {productDetail.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    );
  };

  const WhyUsView = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: BLACK,
            width: "100%",
            py: 7,
            pt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 2,
              ml: 4,
              justifyContent: "left",
            }}
          >
            <SpText
              label="Why US?"
              variant="h5"
              isBold
              sx={{ color: PINK, mr: 1 }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
                lg: "row",
                xl: "row",
              },
              color: WHITE,
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                color: WHITE,
                ml: 4,
                mt: 2,
              }}
            >
              {whyUsLeftStrings.map((text, index) => (
                <Box key={index} sx={{ display: "flex", flexDirection: "row" }}>
                  <CheckCircleOutlineIcon />
                  <SpText
                    label={text}
                    variant="subtitle1"
                    sx={{ ml: 1, textAlign: "left" }}
                  />
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                color: WHITE,
                ml: 4,
                mt: 2,
              }}
            >
              {whyUsRightStrings.map((text, index) => (
                <Box key={index} sx={{ display: "flex", flexDirection: "row" }}>
                  <CheckCircleOutlineIcon />
                  <SpText label={text} variant="subtitle1" sx={{ ml: 1 }} />
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
                lg: "row",
                xl: "row",
              },
              color: WHITE,
              mt: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                color: WHITE,
                ml: 4,
                mt: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <WorkspacePremiumIcon fontSize="large" />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <SpText
                    label="Elight"
                    variant="h5"
                    isBold
                    sx={{ ml: 1, textAlign: "left" }}
                  />
                  <SpText
                    label="The number of national and international certification"
                    variant="subtitle1"
                    sx={{ ml: 1, textAlign: "left" }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                color: WHITE,
                ml: 4,
                mt: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", mr: 2 }}>
                <HistoryIcon fontSize="large" />
                <SpText
                  label="24hrs turnaround for all RFQs with a dedicated team for internation trade"
                  variant="subtitle1"
                  sx={{ ml: 1, textAlign: "left" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: PINK,
            width: "100%",
            py: 2,
            pt: 2,
          }}
        >
          <Grid container spacing={1} sx={{ padding: 1.5 }}>
            {whyUsCompanyData.map((serviceObj, index) => (
              <Grid key={index} item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 3,
                    backgroundColor: BLACK,
                    borderRadius: 2,
                    boxShadow: 1,
                    height: 100,
                    color: WHITE,
                  }}
                >
                  {serviceObj.image}
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{ ml: 2, textAlign: "left" }}
                    >
                      {serviceObj.value}
                    </Typography>
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{ ml: 2, textAlign: "left" }}
                    >
                      {serviceObj.field}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    );
  };

  const GlobalPresenceView = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", mx: 4 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            ml: 4,
            my: 4,
            justifyContent: "center",
          }}
        >
          <SpText label="GLOBAL PRESENCE" variant="h5" isBold />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
            width: "100%",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", width: "100%", mr: 4 }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <SpText
                label="To accomplish Splenz Pharmaceuticals achievements in Pharmaceutical Exports, Ministry of Commerce & Industry has honoured us with badge of ONE STAR EXPORT HOUSE. We have earned accolades and recognition from customers in international market due to our indefatigable and determined commitment towards quality and customer satisfaction. Bringing all these factors together, Splenz Pharmaceuticals has created a regular overseas presence in over 86 countries."
                variant="subtitle1"
                sx={{ textAlign: "justify", alignContent: "left", mb: 2 }}
              />
              <img
                src={MapImage}
                alt="map"
                style={{ display: "flex", maxWidth: "100%", height: "auto" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              my: { xs: 2, sm: 2, lg: 0, xl: 0 },
            }}
          >
            <SpText
              label="Our Branded and Generic medicines enjoy steady demand in following countries:"
              variant="subtitle1"
              sx={{ textAlign: "justify", alignContent: "left", mb: 2 }}
            />
            {globalPresence.map((item, index) => (
              <Accordion
                key={index}
                expanded={globalExpanded === index}
                onChange={handleGlobalChange(index)}
                sx={{ my: 1, transition: 'all 0.3s ease' }}
              >
                <AccordionSummary
                  expandIcon={
                    globalExpanded === index ? (
                      <IndeterminateCheckBoxIcon sx={{ color: WHITE }} />
                    ) : (
                      <AddBoxIcon />
                    )
                  }
                  id={`panel${index}-header`}
                  sx={{
                    backgroundColor: globalExpanded === index ? BLACK : WHITE,
                    color: globalExpanded === index ? WHITE : BLACK,
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", flexShrink: 0 }}>
                    {item.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography sx={{ textAlign: "justify" }}>
                    {item.description}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Box>
    );
  };

  const DedicationView = () => {
    return (
      <Box sx={{ backgroundColor: "#c8c8c6", mt: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", mx: 4, pb: 5 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              ml: 4,
              my: 4,
              justifyContent: "center",
            }}
          >
            <SpText label="WE ARE DEDICATED TO OUR CUSTOMERS AND PRODUCTS" variant="h5" isBold />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                lg: "row",
                xl: "row",
              },
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                mr: 4,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <img
                  src={DedicatedImage}
                  alt="map"
                  style={{ marginBottom: 20, maxWidth: "100%", height: "auto" }}
                />
                <SpText
                  label="Our organization has been committed to providing a wide range of quality and affordable Healthcare solutions to a large number of healthcare Institutions and medical facilities. We intend to be the ideal partner for our clients and our aim is to deliver a diverge array of Quality healthcare cunnliec and Pharma Product."
                  variant="subtitle1"
                  sx={{ textAlign: "justify", alignContent: "left" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                my: { xs: 2, sm: 2, lg: 0, xl: 0 },
              }}
            >
              {dedicatedArray.map((item, index) => (
                <Accordion
                  key={index}
                  expanded={dedicatedExpanded === index}
                  onChange={handleDedicatedChange(index)}
                  sx={{ my: 1, transition: 'all 0.3s ease' }}
                >
                  <AccordionSummary
                    expandIcon={
                      dedicatedExpanded === index ? (
                        <IndeterminateCheckBoxIcon sx={{ color: WHITE }} />
                      ) : (
                        <AddBoxIcon />
                      )
                    }
                    sx={{
                      backgroundColor:
                        dedicatedExpanded === index ? BLACK : WHITE,
                      color: dedicatedExpanded === index ? WHITE : BLACK,
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {item.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <img
                        src={item.image}
                        alt="map"
                        style={{
                          marginBottom: 20,
                          width: "30%",
                          marginRight: 10,
                        }}
                      />
                      <SpText
                        label={item.description}
                        variant="subtitle2"
                        sx={{ textAlign: "justify", alignContent: "left" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        className="text-link"
                        variant="body2"
                        sx={{ color: PINK }}
                        onClick={() => navigate(item.readMoreRoute)}
                      >
                        Read More...
                      </Typography>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const SlidingView = ({ banner, title, subtitle, description }: any) => {
    return (
      <Box
        sx={{
          textAlign: "center",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          component="img"
          src={banner}
          alt={subtitle}
          sx={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: { xs: "none", sm: "none", lg: "flex", xl: "flex" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: "#000",
            backgroundColor: "rgba(255, 255, 255, .0)",
            width: "40%",
            ml: 10,
          }}
        >
          <SpText label={title} variant="h5" isBold />
          <SpText
            label={subtitle}
            variant="h4"
            isBold
            sx={{ color: PINK, fontWeight: "bold", margin: "10px 0" }}
          />
          <SpText label={description} variant="h6" sx={{ maxWidth: "600px" }} />
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {/* <SlidingView {...slides[currentIndex]} /> */}
      <AutoSlider slides={slides}/>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <SplenzPharmaProductView />
        <WelcomeView />
        <OurServicesView />
        <CompleteRangeView />
        <WhyUsView />
        <GlobalPresenceView />
        <DedicationView />
      </Box>
    </Box>
  );
};

export default HomePageView;
