import Button from "@mui/material/Button";
import "../../styles/button-style.css";

interface SpButtonProps {
  variant?: "text" | "contained" | "outlined";
  disabled?: boolean;
  label: string;
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  size?: "small" | "medium" | "large";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  sx?: object;
  onClick?: () => void;
}

const SpButton: React.FC<SpButtonProps> = ({
  variant = "contained",
  disabled = false,
  label,
  color = "primary",
  size = "medium",
  startIcon,
  endIcon,
  fullWidth = true,
  sx = {},
  onClick,
}) => {
  return (
    <Button
      className="button"
      variant={variant}
      disabled={disabled}
      color={color}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      fullWidth={fullWidth}
      sx={{ ...sx, fontSize: "17px", fontWeight: "bold" }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default SpButton;
