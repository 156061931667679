export const headersMenu = [
    {
        id: 1,
        name: 'home',
        route: '/',
        children: []
    },
    {
        id: 2,
        name: 'company profile',
        route: '',
        children: [
            {
                name: 'about us',
                route: '/about-us'
            },
            {
                name: 'mission vision',
                route: '/mission-vision'
            },
            {
                name: 'management',
                route: '/management'
            },
            {
                name: 'why us',
                route: '/why-us'
            },
            {
                name: 'quality policy',
                route: '/quality-policy'
            },
            {
                name: 'client satisfaction',
                route: '/client-satisfaction'
            }
        ]
    },
    {
        id: 3,
        name: 'our products',
        route: '',
        children: [
            {
                name: 'gastroenterology',
                route: '/gastroenterology'
            },
            {
                name: 'urology',
                route: '/urology'
            },
            {
                name: 'nephrology',
                route: '/nephrology'
            },
            {
                name: 'gynaecology',
                route: '/gynaecology'
            }
        ]
    },
    {
        id: 4,
        name: 'gallery',
        route: '/gallery',
        children: []
    },
    {
        id: 5,
        name: 'certification',
        route: '/certification',
        children: []
    },
    {
        id: 6,
        name: 'our brands',
        route: '/our-brands',
        children: []
    },
    {
        id: 7,
        name: 'contact us',
        route: '/contact-us',
        children: []
    }
]