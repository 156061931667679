import {
  Box,
  Divider,
  List,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import SpText from "../components/common/sp-text";
import { BLACK, F_BORDER, PINK } from "../utils/colors";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  COMPANY_ALTERNATE_MOBILE,
  COMPANY_CONTACT_EMAIL,
  COMPANY_HEADQUARTER_ADDRESS,
  COMPANY_MOBILE,
  COMPANY_SERVICE_EMAIL,
} from "../constants/company-details";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailIcon from "@mui/icons-material/Mail";
import SpTextInputField from "../components/common/sp-text-field";
import SpButton from "../components/common/sp-button";
import "../styles/text-style.css";

const ContactUsPageView = () => {
  const leftPanelView = () => {
    return (
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          px: 2,
        }}
      >
        <ListItem alignItems="flex-start" sx={{ my: 3 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LocationOnIcon fontSize="large" />
            <SpText
              label={COMPANY_HEADQUARTER_ADDRESS}
              variant="body2"
              sx={{ textAlign: "left", ml: 2 }}
            />
          </Box>
        </ListItem>
        <Divider component="li" />
        <ListItem alignItems="flex-start" sx={{ my: 3 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <PhoneIphoneIcon fontSize="large" />
            <Box>
            {renderTitleAndValueView("Mobile:", COMPANY_MOBILE, `tel:${COMPANY_MOBILE}`)}
            {renderTitleAndValueView("Phone:", COMPANY_ALTERNATE_MOBILE, `tel:${COMPANY_ALTERNATE_MOBILE}`)}
            </Box>
          </Box>
        </ListItem>
        <Divider component="li" />
        <ListItem alignItems="flex-start" sx={{ my: 3 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <MailIcon fontSize="large" />
            <Box>
              <Typography
                className="text-link"
                variant="body2"
                sx={{ textAlign: "left", ml: 2, color: BLACK }}
                onClick={() =>
                  window.open(`mailto:${COMPANY_CONTACT_EMAIL}`, "_blank")
                }
              >
                {`${COMPANY_CONTACT_EMAIL}`}
              </Typography>
              <Typography
                className="text-link"
                variant="body2"
                sx={{ textAlign: "left", ml: 2, color: BLACK }}
                onClick={() =>
                  window.open(`mailto:${COMPANY_SERVICE_EMAIL}`, "_blank")
                }
              >
                {`${COMPANY_SERVICE_EMAIL}`}
              </Typography>
            </Box>
          </Box>
        </ListItem>
      </List>
    );
  };

  const renderTitleAndValueView = (title: string, value: string, url: string) => {
    return (
      <Box display={"flex"}>
        <SpText
          label={title}
          variant="body2"
          sx={{ textAlign: "left", ml: 2 }}
        />
        <Typography
          className="text-link"
          variant="body2"
          sx={{ textAlign: "left", ml: 1, color: BLACK }}
          onClick={() => (url ? window.open(url, "_blank") : null)}
        >
          {`${value}`}
        </Typography>
      </Box>
    );
  }
  
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", ml: 4, my: 5 }}>
        <SpText label="Contact Us" variant="h5" isBold />
      </Box>
      <Box
        sx={{
          display: { xs: "flex", sm: "flex", lg: "flex", xl: "flex" },
          flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: { xs: "flex", sm: "flex", lg: "flex", xl: "flex" },
            border: `0.5px solid ${F_BORDER}`,
            mx: 4,
            // width: "40%",
          }}
        >
          {leftPanelView()}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: { xs: 4, sm: 4 },
            mr: { xs: 4, sm: 4, lg: 4, xl: 4 },
            width: { lg: "100%", xl: "100%" },
          }}
        >
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", lg: "flex", xl: "flex" },
              flexDirection: {
                xs: "column",
                sm: "column",
                lg: "row",
                xl: "row",
              },
              mb: 2,
              mt: { xs: 3, sm: 3, lg: 0, xl: 0 },
            }}
          >
            <TextField label="Name" required sx={{ mr: 2, width: "100%" }} />
            <TextField
              label="Email"
              required
              sx={{ width: "100%", mt: { xs: 3, sm: 3, lg: 0, xl: 0 } }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                lg: "row",
                xl: "row",
              },
              mb: 2,
            }}
          >
            <TextField label="Phone no" sx={{ width: "100%", mr: 2 }} />
            <TextField
              label="Country"
              required
              sx={{ width: "100%", mt: { xs: 3, sm: 3, lg: 0, xl: 0 } }}
            />
          </Box>
          <SpTextInputField
            label="Leave Message"
            multiline
            rows={5}
            sx={{ width: "100%" }}
          />
          <SpButton
            label="Submit"
            sx={{
              backgroundColor: PINK,
              '&:hover': { backgroundColor: BLACK },
              width: "20vh",
              display: "flex",
              mt: 2,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUsPageView;
