import { Box } from "@mui/material";
import SpText from "../components/common/sp-text";
import { PINK, WHITE } from "../utils/colors";
import "../styles/image-grid.css";
import { gastroenterologyProductsDetails } from "../constants/products/gastroenterology";
import { urologyProductsDetails } from "../constants/products/urology";
import { gynaecologyProductsDetails } from "../constants/products/gynaecology";

const allProducts = [...gastroenterologyProductsDetails, ...urologyProductsDetails, ...gynaecologyProductsDetails]

const GalleryPageView = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", ml: 4, my: 5 }}>
        <SpText
          label="Our Brand of Splenz Pharmaceuticals Pvt. Ltd."
          variant="h5"
          isBold
        />
      </Box>
      <Box className="image-grid" sx={{ mx: 2 }}>
        {allProducts.map((productObj, index) => (
          <div
            key={index}
            className="image-item"
            style={{
              backgroundColor: WHITE,
              boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)",
              padding: 20,
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <img src={productObj.image} alt={`img-${index}`} />
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default GalleryPageView;
