import Mesalenz12G from "../../assets/products/mesalenz_1.2g.jpg"
import Splenflox from "../../assets/products/splenfox.jpg"
import Splond4Md from "../../assets/products/splond_4_md.jpg"
import SypMakUti from "../../assets/products/syp_mak_uti.jpg"
import TabFurenz100Sr from "../../assets/products/furenz_100_sr.jpg"
import TabUtVox from "../../assets/products/ut_vox.jpg"

export const gynaecologyProductsDetails = [
    {
        id: 9,
        name: 'Tab. Mesalenz 1.2gm',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: Mesalenz12G
    },
    {
        id: 10,
        name: 'Tab. Splenflox',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: Splenflox
    },
    {
        id: 11,
        name: 'Tab. Splond 4 MD',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: Splond4Md
    },
    {
        id: 12,
        name: 'Syp. Mak-uti',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: SypMakUti,
        newlyAdded: true
    },
    {
        id: 13,
        name: 'Tab. Furenz 100 SR',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: TabFurenz100Sr
    },
    {
        id: 14,
        name: 'Tab. UTvox',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: TabUtVox
    }
]