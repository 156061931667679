import { Box } from "@mui/material";
import SpText from "../../components/common/sp-text";
import { PINK } from "../../utils/colors";
import Management from "../../assets/app-images/management.jpg";

const ManagementPageView = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", ml: 4, my: 5 }}>
        <SpText label="Leadership & Management" variant="h5" isBold />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
          mx: 5,
        }}
      >
        <img src={Management} alt="company-logo" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mx: { xs: 0, sm: 0, lg: 5, xl: 5 },
            my: { xs: 2, sm: 2, lg: 0, xl: 0 },
          }}
        >
          <SpText
            label="The leadership is provided by a group of qualified Professionals who believe in value based management and ethical and scientific promotion of Pharmaceutical products to the Medical fraternity. The day-to-day management is in the hands of Pharmaceuticals professionals with rich experience in Indian Pharmaceuticals market. Splenz believes in ethical leadership and value-based professional management"
            variant="body1"
            align="justify"
          />
          <Box sx={{ display: "flex", flexDirection: "row", my: 2 }}>
            <SpText label="Leadership & Management" variant="h5" isBold />
          </Box>
          <SpText
            label="Splenz believes as a corporate, it has tremendous responsibility towards the society it belongs to. A substantial part of its profit will be spent on the projects aimed at “better health through better hygiene” in the remote villages, inhabited mostly by poor and little-educated or uneducated people. The purpose is to practise what we preach…as advocated by Mr. Narayan Murthy (of Infosys) in his brilliant book ”A better India, A better world”, Splenz would like to practise “compassionate Capitalism”"
            variant="body1"
            align="justify"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ManagementPageView;
