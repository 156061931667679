import { Box } from "@mui/material";
import SpText from "../components/common/sp-text";
import { WHITE } from "../utils/colors";
// import Brand_1 from "../assets/brands/brand_1.jpg";
// import Brand_2 from "../assets/brands/brand_2.jpg";
// import Brand_3 from "../assets/brands/brand_3.jpg";
// import Brand_4 from "../assets/brands/brand_4.jpg";
// import Brand_5 from "../assets/brands/brand_5.jpg";
// import Brand_6 from "../assets/brands/brand_6.jpg";
// import Brand_7 from "../assets/brands/brand_7.jpg";
// import Brand_8 from "../assets/brands/brand_8.jpg";
// import Brand_9 from "../assets/brands/brand_9.jpg";
// import Brand_10 from "../assets/brands/brand_10.jpg";
// import Brand_11 from "../assets/brands/brand_11.jpg";
// import Brand_12 from "../assets/brands/brand_12.jpg";
// import Brand_13 from "../assets/brands/brand_13.jpg";
// import Brand_14 from "../assets/brands/brand_14.jpg";
// import Brand_15 from "../assets/brands/brand_15.jpg";
// import Brand_16 from "../assets/brands/brand_16.jpg";
// import Brand_17 from "../assets/brands/brand_17.jpg";
// import Brand_18 from "../assets/brands/brand_18.jpg";
import "../styles/image-grid.css";
import { snakeCase } from "lodash";
import GastroentrologyImage from "../assets/products/category/gastroenterology.jpg"
import UrologyImage from "../assets/products/category/urology.jpg";
import GynaecologyImage from "../assets/products/category/gynaecology.jpg";
import NephrologyImage from "../assets/products/category/nephrology.jpg";
import { useNavigate } from "react-router-dom";

// const brandsList = [
//   {
//     id: 1,
//     name: "dutamsin",
//     image: Brand_1,
//   },
//   {
//     id: 2,
//     name: "fibrolac",
//     image: Brand_2,
//   },
//   {
//     id: 3,
//     name: "",
//     image: Brand_3,
//   },
//   {
//     id: 4,
//     name: "",
//     image: Brand_4,
//   },
//   {
//     id: 5,
//     name: "",
//     image: Brand_5,
//   },
//   {
//     id: 6,
//     name: "",
//     image: Brand_6,
//   },
//   {
//     id: 7,
//     name: "",
//     image: Brand_7,
//   },
//   {
//     id: 8,
//     name: "",
//     image: Brand_8,
//   },
//   {
//     id: 9,
//     name: "",
//     image: Brand_9,
//   },
//   {
//     id: 10,
//     name: "",
//     image: Brand_10,
//   },
//   {
//     id: 11,
//     name: "",
//     image: Brand_11,
//   },
//   {
//     id: 12,
//     name: "",
//     image: Brand_12,
//   },
//   {
//     id: 13,
//     name: "",
//     image: Brand_13,
//   },
//   {
//     id: 14,
//     name: "",
//     image: Brand_14,
//   },
//   {
//     id: 15,
//     name: "",
//     image: Brand_15,
//   },
//   {
//     id: 16,
//     name: "",
//     image: Brand_16,
//   },
//   {
//     id: 17,
//     name: "",
//     image: Brand_17,
//   },
//   {
//     id: 18,
//     name: "",
//     image: Brand_18,
//   },
// ];

const ProductCategory = [
  {
    id: 1,
    name: "Gastroenterology",
    image: GastroentrologyImage,
  },
  {
    id: 2,
    name: "Urology",
    image: UrologyImage,
  },
  {
    id: 3,
    name: "Nephrology",
    image: NephrologyImage,
  },
  {
    id: 4,
    name: "Gynaecology",
    image: GynaecologyImage,
  }
];

const OurBrandsPageView = () => {
  const navigate = useNavigate()
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", ml: 4, my: 5 }}>
        <SpText
          label="Our Brand of Splenz Pharmaceuticals Pvt. Ltd."
          variant="h5"
          isBold
        />
      </Box>
      {/* <Box className="image-grid" sx={{ mx: 2 }}>
        {brandsList.map((brandObj, index) => (
          <div
            key={index}
            className="image-item"
            style={{
              backgroundColor: WHITE,
              boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)",
              padding: 20,
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <img src={brandObj.image} alt={`img-${index}`} />
          </div>
        ))}
      </Box> */}
      <Box className="image-grid" sx={{ mx: 2 }}>
          {ProductCategory.map((productObj: any, index: number) => (
            <div
              key={index}
              className="image-item"
              style={{
                backgroundColor: WHITE,
                boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)",
                padding: 20,
                borderRadius: "20px",
                cursor: "pointer",
                maxWidth: "450px",
              }}
              onClick={() => navigate(`/${snakeCase(productObj.name)}`)}
            >
              <img src={productObj.image} alt={`img-${index}`} />
              <Box className="text">
                <SpText
                  label={productObj.name}
                  variant="h4"
                  isBold
                  sx={{ mt: 2 }}
                  onClick={() => navigate(`/${snakeCase(productObj.name)}`)}
                />
              </Box>
            </div>
          ))}
        </Box>
    </Box>
  );
};

export default OurBrandsPageView;
