import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { headersMenu } from "../constants/header-menu";
import { startCase } from "lodash";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { PINK, SKY_BLUE, WHITE } from "../utils/colors";
import { Divider } from "@mui/material";
import SpText from "./common/sp-text";
import { COMPANY_NAME } from "../constants/company-details";
import { useNavigate } from "react-router-dom";

const CompanyDrawer = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const [profileNested, setProfileNested] = React.useState(false);
  const [productNested, setProductNested] = React.useState(false);
  const navigate = useNavigate();

  const handleClick = (isNested: number, route: string = "") => {
    
    if (isNested !== -1) {
      if (isNested === 1) {
        setProfileNested(!profileNested)
        setProductNested(false)
      } else {
        setProfileNested(false)
        setProductNested(!productNested)
      }
    } else {
      navigate(route);
      toggleDrawer(false)
      props.isClosed();
    }
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  React.useEffect(() => {
    setOpen(props.isOpenClick);
  }, [props.isOpenClick]);

  const DrawerList = () => {
    return (
      <Box sx={{ width: 250 }} role="presentation" onClick={() => null}>
        <SpText
          label={COMPANY_NAME}
          variant="h6"
          isBold={true}
          onClick={toggleDrawer(false)}
          sx={{ backgroundColor: PINK, color: WHITE, py: 3 }}
        />
        <List>
          {headersMenu.map((menuItem, index) =>
            menuItem.children.length ? (
              <Box key={index}>
                <ListItemButton
                  sx={{
                    // backgroundColor: (index === 1 && profileNested) || (index === 2 && productNested) ? PINK : "",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onClick={() => handleClick(index)}
                >
                  <SpText
                    variant="body2"
                    label={startCase(menuItem.name)}
                    isBold={true}
                  />
                  {(index === 1 && profileNested) || (index === 2 && productNested) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Divider />
                <Collapse
                  key={menuItem.id}
                  in={(index === 1 && profileNested) || (index === 2 && productNested)}
                  timeout="auto"
                  unmountOnExit
                >
                  {menuItem.children.map((menuItemChildren, newIndex) => (
                    <Box key={newIndex}>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() =>
                          handleClick(-1, menuItemChildren.route)
                        }
                      >
                        <SpText
                          variant="body2"
                          label={startCase(menuItemChildren.name)}
                          isBold={true}
                        />
                      </ListItemButton>
                      <Divider />
                    </Box>
                  ))}
                </Collapse>
              </Box>
            ) : (
              <Box key={index}>
                <ListItemButton
                  onClick={() => handleClick(-1, menuItem.route)}
                >
                  <SpText
                    variant="body2"
                    label={startCase(menuItem.name)}
                    isBold={true}
                  />
                </ListItemButton>
                <Divider />
              </Box>
            )
          )}
        </List>
      </Box>
    );
  };

  return (
    <div>
      {/* <Button onClick={toggleDrawer(true)}>Open drawer</Button> */}
      <Drawer
        open={open}
        onClose={() => {
          toggleDrawer(false);
          props.isClosed();
        }}
      >
        {DrawerList()}
      </Drawer>
    </div>
  );
};

export default CompanyDrawer;
