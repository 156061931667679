import "../../styles/floating-button.css"; // Import the CSS file for styling
import { Box } from "@mui/material";
import CallGIF from "../../assets/floatings/call-icon.gif";
import WhatsAppGIF from "../../assets/floatings/whatspp-icon.gif";
import { COMPANY_ALTERNATE_MOBILE } from "../../constants/company-details";

const FloatingButton = () => {
  return (
    <Box className="floating-button" sx={{ ml: 2, mb: 5 }}>
      <Box
        component="img"
        src={CallGIF}
        alt="Description of GIF"
        sx={{
          width: "100%",
          maxWidth: 600,
          height: "auto",
          mb: 1,
        }}
        onClick={() => window.open(`tel:${COMPANY_ALTERNATE_MOBILE}`, "_self")}
      />
      <Box
        component="img"
        src={WhatsAppGIF}
        alt="Description of GIF"
        sx={{
          width: "100%",
          maxWidth: 600,
          height: "auto",
        }}
        onClick={() =>
          window.open(
            "https://api.whatsapp.com/send?phone=919766772587&text=Hey%20there",
            "_blank"
          )
        }
      />
    </Box>
  );
};

export default FloatingButton;
