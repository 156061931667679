import { Box, List, ListItem } from "@mui/material";
import SpText from "../../components/common/sp-text";
import { PINK } from "../../utils/colors";
import QualityPolicyIcon from "../../assets/app-images/quality-policy.jpg";

const QualityPolicyPageView = () => {
  const leftPanelView = () => {
    return (
      <List
        sx={{
          bgcolor: "background.paper",
        }}
      >
        <ListItem alignItems="flex-start" sx={{ my: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <img src={QualityPolicyIcon} alt="company-logo" />
            <SpText
              label="As a pharmaceutical company, our commitment is to improve the health and well-being of people around the world by developing innovative and effective treatments for diseases and medical conditions. We prioritize patient safety, ethical conduct, and environmental sustainability in all aspects of our operations, from research and development to manufacturing and distribution. We adhere to the highest quality and regulatory standards, and invest in ongoing education and training for our employees to ensure they are equipped with the knowledge and skills to deliver on our commitment."
              variant="body1"
              sx={{ textAlign: "justify", mt: 2 }}
            />
          </Box>
        </ListItem>
      </List>
    );
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", ml: 4, my: 2 }}>
        <SpText label="Our Commitment" variant="h5" isBold />
      </Box>
      <Box
        sx={{
          display: { xs: "flex", sm: "flex", lg: "flex", xl: "flex" },
          flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: { xs: "flex", sm: "flex", lg: "flex", xl: "flex" },
            mx: 1.5,
            maxWidth: "600px",
          }}
        >
          {leftPanelView()}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: { xs: 4, sm: 4 },
            mr: { xs: 4, sm: 4, lg: 4, xl: 4 },
            width: { lg: "100%", xl: "100%" },
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
            <SpText label="Quality Policy" variant="h5" isBold />
          </Box>
          <Box sx={{ display: "flex", textAlign: "justify" }}>
            <ul style={{ listStyleType: "square" }}>
              <li style={{ marginTop: 2 }}>
                We manufacture and market Pharmaceutical products, which will
                satisfy continuing needs of our customers.
              </li>
              <li style={{ marginTop: 15 }}>
                This will be achieved by application of sound Quality Management
                System and adopting current Good Manufacturing Practices and
                complying all regulatory requirements.
              </li>
              <li style={{ marginTop: 15 }}>
                While doing so we shall keep in mind safety of our employees,
                all work processes as well as our natural environment.
              </li>
              <li style={{ marginTop: 15 }}>
                The company shall manufacture products of high quality, which
                shall be safe for human consumption and shall have consistent
                properties of dosage form.
              </li>
              <li style={{ marginTop: 15 }}>
                We consider our human resources as one of the biggest asset. Our
                qualified and trained staff is provided constant training to
                upgrade their knowledge and stay conversant with state of art
                technology.
              </li>
              <li style={{ marginTop: 15 }}>
                We value motivation amongst our personnel as an important factor
                to make them more competent and quality conscious.
              </li>
              <li style={{ marginTop: 15 }}>
                The quality assurance of its products is the obligation of the
                company and all the departments of the company share it by
                keeping vigil while purchasing, maintaining, manufacturing,
                testing, distribution and marketing of its products.
              </li>
              <li style={{ marginTop: 15 }}>
                The company shall make available all the requisite measures and
                resources to achieve the above goals.
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default QualityPolicyPageView;
