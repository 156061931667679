import Mesalenz12G from "../../assets/products/mesalenz_1.2g.jpg"

export const nephrologyProductsDetails = [
    {
        id: 9,
        name: 'Tab. Mesalenz 1.2gm',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: Mesalenz12G
    },
]