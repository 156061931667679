import { Box } from "@mui/material";
import SpText from "../../components/common/sp-text";
import { PINK } from "../../utils/colors";
import ClientSatisfaction from "../../assets/app-images/client-satisfaction.jpg"

const ClientSatisfactionPageView = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", ml: 4, my: 5 }}>
        <SpText
          label="Client Satisfaction"
          variant="h5"
          isBold
        />
      </Box>
      <Box sx={{display: 'flex', flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" }, mx: 5, }}>
        <img src={ClientSatisfaction} alt="company-logo" />
        <SpText
          label="Pharmaceutical companies strive to provide the highest level of client satisfaction by consistently delivering safe and effective products that meet the needs of patients. To achieve this, they invest heavily in research and development, quality assurance, and regulatory compliance to ensure their products meet the highest standards. Additionally, pharmaceutical companies provide support services such as patient education and assistance programs, clinical trials, and post-market surveillance to ensure patients receive the best possible care. By prioritizing client satisfaction, pharmaceutical companies aim to improve the health and well-being of individuals and communities, while maintaining trust and confidence in the industry as a whole."
          variant="body1"
          align="justify"
          sx={{ my: { xs: 4, sm: 4, lg: 0, xl: 0 }, ml: { xs: 0, sm: 0, lg: 4, xl: 4 } }}
        />
      </Box>
    </Box>
  );
};

export default ClientSatisfactionPageView;
