import { Box } from "@mui/material";
import SpText from "../../components/common/sp-text";
import { PINK, WHITE } from "../../utils/colors";
import "../../styles/image-grid.css";
import "../../styles/text-style.css";
import { useNavigate } from "react-router-dom";
import { snakeCase } from "lodash";
import { nephrologyProductsDetails } from "../../constants/products/nephrology";

const NephrologyPageView = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", ml: 4, my: 5 }}>
        <SpText label="Products of Nephrology" variant="h5" isBold />
      </Box>
      <Box className="image-grid" sx={{ mx: 2 }}>
        {nephrologyProductsDetails.map((productObj: any, index: number) => (
          <div
            key={index}
            className="image-item"
            style={{
              backgroundColor: WHITE,
              boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)",
              padding: 20,
              borderRadius: "20px",
              cursor: "pointer",
            }}
          >
            <img src={productObj.image} alt={`img-${index}`} />
            <Box className="text">
              <SpText
                label={productObj.name}
                variant="h6"
                isBold
                sx={{ mt: 2 }}
                onClick={() =>
                  navigate(
                    `./${snakeCase(productObj.name)}?id=${productObj.id}`
                  )
                }
              />
            </Box>
          </div>
        ))}
      </Box>
    </Box>
  );
};

export default NephrologyPageView;
