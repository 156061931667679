import { Box } from "@mui/material";
import SpText from "../components/common/sp-text";
import { PINK } from "../utils/colors";
import Certificate1 from "../assets/certification/certificate_1.jpg";
import Certificate2 from "../assets/certification/certificate_2.jpg";

const CertificationPageView = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <SpText
        label="Certifications"
        variant="h5"
        isBold
        sx={{ display: "flex", mr: 1, ml: 4, mt: 5, mb: 2 }}
      />
      <SpText
        label="Pharmaceutical certifications can be critical to ensuring product safety and regulatory compliance. Some commonly recognized certifications in the pharmaceutical industry include ISO 9001, ISO 13485, Good Manufacturing Practice (GMP), Good Laboratory Practice (GLP), and Current Good Manufacturing Practice (cGMP). These certifications cover various aspects of pharmaceutical manufacturing, including quality management systems, testing and analysis, and manufacturing processes."
        variant="body1"
        align="left"
        sx={{ mr: 4, ml: 4, my: 2 }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row", lg: "row", xl: "row" },
          justifyContent: "center",
          gap: 5,
        }}
      >
        <img
          src={Certificate1}
          alt="company-logo"
          style={{ boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)", margin: '30px'}}
        />
        <img
          src={Certificate2}
          alt="company-logo"
          style={{ boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.2)", margin: '30px' }}
        />
      </Box>
    </Box>
  );
};

export default CertificationPageView;
