import { Box } from "@mui/material";
import companyLogo from "../../assets/logo/logo.png";
import { useNavigate } from "react-router-dom";
import SpText from "./sp-text";
import { BLACK, PINK } from "../../utils/colors";
import { keyframes } from "@emotion/react";

// Define keyframes for text entrance animation
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const CompanyLogo = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0 }}>
      <img
        src={companyLogo}
        alt="company-logo"
        style={{
          cursor: "pointer",
          transition: "transform 0.3s ease-in-out, translateY 0.15s",
          width: 70,
        }}
        onClick={() => navigate("")}
        onMouseOver={(e) => {
          e.currentTarget.style.transform = "scale(1.1)";
          e.currentTarget.style.transform += " translateY(-5px)"; // Adds a slight upward movement
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.transform = "scale(1.0) translateY(0px)";
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          // backgroundColor: 'red'
        }}
      >
        <SpText
          label={"Splenz Pharmaceuticals Pvt. Ltd."}
          isBold
          noWrap={true}
          sx={{
            color: PINK,
            fontWeight: "bold",
            fontFamily: "Rockwell, serif",
            letterSpacing: 1.2,
            // textTransform: "uppercase",
            animation: `${fadeInUp} 0.6s ease-out`,
            fontSize: {
              xs: '1.1rem',
              sm: '1.8rem',
              md: '1.9rem',
              lg: '1.9rem'
            }
          }}
        />
        <SpText
          label={"Enriching Patients Life"}
          sx={{
            mt: {
              xs: -0.7,
              sm: -1.5,
              md: -1.5,
              lg: -1.5
            },
            mr: 0.5,
            color: BLACK,
            // textTransform: "uppercase",
            letterSpacing: 1.2,
            textAlign: "right",
            fontSize:{
              xs: '1rem',
              sm: '1.5rem',
              md: '1.5rem',
              lg: '1.5rem'
            },
            fontFamily: "Dancing Script, cursive",
            fontWeight: "bold",
            animation: `${fadeInUp} 0.6s ease-out 0.2s`,
          }}
        />
      </Box>
    </Box>
  );
};

export default CompanyLogo;
