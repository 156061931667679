import SypMakB6 from "../../assets/products/syp_mak_b6.jpg"
import Dutamsin from "../../assets/products/dutamsin.jpg"
import TabMakB6 from "../../assets/products/tab_mak_b6.jpg"
import TabSplentamD from "../../assets/products/splentam_d.jpg"
import TabSplentam02 from "../../assets/products/splentum_0.2.jpg"
import TabSplentam04 from "../../assets/products/splentum_0.4.jpg"

export const urologyProductsDetails = [
    {
        id: 15,
        name: 'Syp. MaK B6',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: SypMakB6,
        newlyAdded: true
    },
    {
        id: 16,
        name: 'Tab. Dutamsin',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: Dutamsin
    },
    {
        id: 17,
        name: 'Tab. MaK B6',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: TabMakB6
    },
    {
        id: 18,
        name: 'Tab. Splentam – D',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: TabSplentamD
    },
    {
        id: 19,
        name: 'Tab. Splentam 0.2',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: TabSplentam02
    },
    {
        id: 20,
        name: 'Tab. Splentam 0.4',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: TabSplentam04
    }
]