import PecDsr from "../../assets/products/pec_dsr.jpg"
import SplenzEPlus from "../../assets/products/splenz_e_plus.jpg"
import SplenzPlus from "../../assets/products/splenz_plus.jpg"
import FibrolacPowder200Gm from "../../assets/products/fabrolac_powder_200gm.jpg"
import FibrolacPowder90Gm from "../../assets/products/fabrolac_powder_90gm.jpg"
import SplPro from "../../assets/products/spl_pro.jpg"
import Sucrafenz from "../../assets/products/sucrafenz.jpg"
import SucrafenzO from "../../assets/products/sucrafenz_o.jpg"


export const gastroenterologyProductsDetails = [
    {
        id: 1,
        name: 'Cap. PEC DSR',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: PecDsr
    },
    {
        id: 2,
        name: 'Cap. Splenz E Plus',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: SplenzEPlus
    },
    {
        id: 3,
        name: 'Cap. Sulpenz Plus',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: SplenzPlus
    },
    {
        id: 4,
        name: 'Fibrolac Powder (200gm)',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: FibrolacPowder200Gm,
        newlyAdded: true
    },
    {
        id: 5,
        name: 'Fibrolac Powder (90gm)',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: FibrolacPowder90Gm,
        newlyAdded: true
    },
    {
        id: 6,
        name: 'SPL PRO (Protein Powder)',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: SplPro,
        newlyAdded: true
    },
    {
        id: 7,
        name: 'Sucrafenz',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: Sucrafenz
    },
    {
        id: 8,
        name: 'Sucrafenz -0 Suspension',
        description: 'Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India.',
        route: '',
        image: SucrafenzO
    }
]