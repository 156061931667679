import { Box, Button } from "@mui/material";
import SpText from "../../components/common/sp-text";
import { BLACK, PINK } from "../../utils/colors";
import "../../styles/button-style.css";
import { gastroenterologyProductsDetails } from "../../constants/products/gastroenterology";
import { gynaecologyProductsDetails } from "../../constants/products/gynaecology";
import { urologyProductsDetails } from "../../constants/products/urology";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";

interface ProductDetails {
  id: number;
  name: string;
  description: string;
  image: any;
  route: string;
}

const ProductDetailsPageView = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [productDetails, setProductDetails] = useState<ProductDetails | null>(
    null
  );

  useEffect(() => {
    const allProducts = [
      ...gastroenterologyProductsDetails,
      ...gynaecologyProductsDetails,
      ...urologyProductsDetails,
    ];
    const id = Number(searchParams.get("id"));
    if (id) {
      const productDetailsById = allProducts.find(
        (productObj) => productObj.id === id
      );
      setProductDetails(productDetailsById || null);
    }
  }, [searchParams]);

  const getWordBeforeSpace = (inputText: string) => {
    const firstSpaceIndex = inputText.indexOf(" ");
    if (firstSpaceIndex === -1) {
      // If there's no space, return the whole string as the first word and an empty string for the rest
      return [inputText, ""];
    } else {
      const firstWord = inputText.substring(0, firstSpaceIndex);
      const remainingWords = inputText.substring(firstSpaceIndex + 1);
      return [firstWord, remainingWords];
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", ml: 4, my: 5 }}>
        <SpText
          label={getWordBeforeSpace(productDetails?.name || "")[0]}
          variant="h5"
          isBold
          sx={{ color: PINK, mr: 1 }}
        />
        <SpText
          label={getWordBeforeSpace(productDetails?.name || "")[1]}
          variant="h5"
          isBold
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            lg: "row",
            xl: "row",
          },
          mx: { xs: 2, sm: 3, lg: 4, xl: 4 },
        }}
      >
        <Box sx={{ display: "flex", width: "100%" }}>
          <img
            src={productDetails?.image}
            alt={`img-${productDetails?.id}`}
            width={"100%"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mx: { xs: 0, sm: 0, lg: 4, xl: 4 },
            justifyContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "left",
          }}
        >
          <SpText
            sx={{ mt: { xs: 3, sm: 3, lg: 0, xl: 0 }, textAlign: "left" }}
            label={"Products Description"}
            variant="h3"
          />
          <SpText
            label={productDetails?.description || ""}
            sx={{ mt: 2, textAlign: "left" }}
            variant="subtitle1"
          />
          <SpText
            label={"Services offered by Splenz Pharmaceuticals Pvt. Ltd."}
            sx={{ mt: 2, textAlign: "left" }}
            variant="h4"
            isBold
          />
          <Box sx={{ display: "flex", textAlign: "justify", mt: 2 }}>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                Product Range with Excellent Quality: Wide Range of Products.
              </li>
              <li>Monopoly Privileges: Pharma Franchise all over India.</li>
              <li>
                Marketing and Promotion: Inputs: Reminder card, visual aids etc.
              </li>
              <li>Advance Dispatch System: Same day product dispatch.</li>
              {/* <li>
                Other Features: Low investment and great returns on PCD Pharma.
              </li> */}
            </ul>
          </Box>
          <SpText
            label={
              "A better India, A better world, Splenz would like to practise compassionate Capitalism"
            }
            sx={{ mt: 2 }}
            variant="subtitle1"
            isBold
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: PINK,
              '&:hover': { backgroundColor: BLACK },
              width: "20vh",
              display: "flex",
              mt: 2,
              fontSize: "17px",
              fontWeight: "bold",
            }}
            onClick={() => navigate('/contact-us')}
          >
            ENQUIRE NOW
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductDetailsPageView;
