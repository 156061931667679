import Typography from "@mui/material/Typography";

interface Props {
  label: string;
  variant?:
    | "body1"
    | "body2"
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "inherit"
    | "overline"
    | "subtitle1"
    | "subtitle2";
  align?: "center" | "inherit" | "justify" | "left" | "right";
  noWrap?: boolean;
  sx?: any;
  paragraph?: boolean;
  isBold?: boolean;
  onClick?: () => void;
  className?: string;
}

const SpText: React.FC<Props> = ({
  label,
  variant = "body1",
  align = "center",
  noWrap = false,
  sx,
  paragraph = false,
  isBold = false,
  onClick,
  className,
}) => {
  return (
    <Typography
      variant={variant}
      align={align}
      className={className}
      noWrap={noWrap}
      sx={sx}
      paragraph={paragraph}
      onClick={onClick}
      fontWeight={isBold ? "bold" : "normal"}
    >
      {label}
    </Typography>
  );
};

export default SpText;
