import { Box } from "@mui/material";
import SpText from "../../components/common/sp-text";
import { PINK } from "../../utils/colors";
import Mission from "../../assets/app-images/mission-vision/mission.jpg";
import Value from "../../assets/app-images/mission-vision/value.jpg";
import Vision from "../../assets/app-images/mission-vision/vision.jpg";

const MissionVisionPageView = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
        justifyContent: "center",
      }}
    >
      {/* Box 1 Splenz Mission */}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row", ml: 4, my: 5 }}>
          <SpText label="Splenz Mission" variant="h5" isBold />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
            mx: 3,
            maxWidth: "400px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <img src={Mission} alt="company-logo" />
            <SpText
              label="Promote the brands most ethically and scientifically to the customers through a well-trained team of Marketing personnel who will enjoy what they do. Leave no stone unturned to make the customers perceive that Splenz Pharma is the bench mark for quality not only for products efficacy, safety and presentation but also for every aspect of the company like Personnel, Management and Ethics."
              variant="body1"
              align="justify"
              sx={{ mt: 2 }}
            />
          </Box>
        </Box>
      </Box>

      {/* Box 2 Splenz Vision */}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row", ml: 4, my: 5 }}>
          <SpText label="Splenz Vision" variant="h5" isBold />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
            mx: 3,
            maxWidth: "400px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <img src={Vision} alt="company-logo" />
            <SpText
              label="Practise Quality in every aspect and ensure the customers perceive as an organization with obsession to QUALITY Make the Division a professionally managed organization which the customers will see as the one that never compromises on"
              variant="body1"
              align="justify"
              sx={{ mt: 2 }}
            />
            <Box sx={{ display: "flex", textAlign: "justify", ml: 1 }}>
              <ul style={{ listStyleType: "disc" }}>
                <li>QUALITY w.r.t</li>
                <li>Product & Pack</li>
                <li>Management & Personnel</li>
                <li>Marketing inputs</li>
                <li>Ethics</li>
              </ul>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Box 3 Splenz Value */}
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row", ml: 4, my: 5 }}>
          <SpText label="Splenz Value" variant="h5" isBold />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
            mx: 3,
            maxWidth: "400px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <img src={Value} alt="company-logo" />
            <SpText
              label="Splenz believes in practising high value based management towards its employees, customers and Patients By providing a compassionate, but professional management style within and customer-centric approach towards the market. Splenz will never sell any Pharma product that will not be consumed with confidence and faith by the dear and near of its promoters. In short, one can say, if the “Log-in ID” of Splenz is “Specialty”, the pass word is “Quality”!"
              variant="body1"
              align="justify"
              sx={{ mt: 2 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MissionVisionPageView;
