import { Component } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group"; // Import transition components
import HomePageView from "./views/homepage/homepage";
import CompanyHeaders from "./components/header";
import ContactUsPageView from "./views/contact-us-page";
import CompanyFooters from "./components/footer";
import { Box } from "@mui/material";
import OurBrandsPageView from "./views/our-brands-page";
import GalleryPageView from "./views/gallery-page";
import CertificationPageView from "./views/certification-page";
import AboutUsPageView from "./views/company-profile/about-us";
import MissionVisionPageView from "./views/company-profile/mission-vision";
import ManagementPageView from "./views/company-profile/management";
import WhyUsPageView from "./views/company-profile/why-us";
import QualityPolicyPageView from "./views/company-profile/quality-policy";
import ClientSatisfactionPageView from "./views/company-profile/client-satisfaction";
import GastroentrologyPageView from "./views/products/gastroentrology";
import UrologyPageView from "./views/products/urology";
import GynaecologyPageView from "./views/products/gynaecology";
import ProductDetailsPageView from "./views/products/details";
import ScrollToTop from "./components/common/scroll-to-top";
import FloatingButton from "./components/common/floating-button";
import FloatingModalButton from "./components/common/floating-modal-button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import NephrologyPageView from "./views/products/nephrology";

const theme = createTheme({
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

function AppRoutes() {
  const location = useLocation();

  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={location.pathname} // Ensure animation triggers on location change
        timeout={500} // Set timeout for the animation
        classNames="page" // Use this class for animations
      >
        <Box sx={{ mb: 4 }}>
          <Routes location={location}>
            <Route path="/">
              <Route index element={<HomePageView />} />
              <Route path="/contact-us" element={<ContactUsPageView />} />
              <Route path="/our-brands" element={<OurBrandsPageView />} />
              <Route path="/gallery" element={<GalleryPageView />} />
              <Route path="/certification" element={<CertificationPageView />} />
              <Route path="/about-us" element={<AboutUsPageView />} />
              <Route path="/mission-vision" element={<MissionVisionPageView />} />
              <Route path="/management" element={<ManagementPageView />} />
              <Route path="/why-us" element={<WhyUsPageView />} />
              <Route path="/quality-policy" element={<QualityPolicyPageView />} />
              <Route path="/client-satisfaction" element={<ClientSatisfactionPageView />} />
              <Route path="/gastroenterology" element={<GastroentrologyPageView />} />
              <Route path="/gastroenterology/:name" element={<ProductDetailsPageView />} />
              <Route path="/urology" element={<UrologyPageView />} />
              <Route path="/urology/:name" element={<ProductDetailsPageView />} />
              <Route path="/nephrology" element={<NephrologyPageView />} />
              <Route path="/nephrology/:name" element={<ProductDetailsPageView />} />
              <Route path="/gynaecology" element={<GynaecologyPageView />} />
              <Route path="/gynaecology/:name" element={<ProductDetailsPageView />} />
            </Route>
          </Routes>
        </Box>
      </CSSTransition>
    </TransitionGroup>
  );
}

class App extends Component {
  render() {
    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ScrollToTop />
            <CompanyHeaders />
            <AppRoutes />
            <CompanyFooters />
            <FloatingButton />
            <FloatingModalButton />
          </BrowserRouter>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
