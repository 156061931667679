import { Box } from "@mui/material";
import SpText from "../../components/common/sp-text";
import { PINK } from "../../utils/colors";

const AboutUsPageView = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", ml: 4, my: 5 }}>
        <SpText
          label="Welcome to Splenz Pharmaceuticals Pvt. Ltd."
          variant="h5"
          isBold
        />
      </Box>
      <Box sx={{ mx: 2 }}>
        <SpText
          label="Most Big things start small! And that applies to Splenz Pharma as well Splenz pharmaceuticals is a Private limited company founded by Hon. Director Mr. Padmakumar P in 2014 with its Head Quarters based at Pune."
          variant="body1"
          align="justify"
          sx={{ mr: 4, ml: 4, my: 2 }}
        />
        <SpText
          label="It was a humble beginning in 2014 with just 2 employees and 5 Gastroenterology and Urology products. And then a journey started by a group of professionals who have a combined experience of 75 plus years in the field of Pharmaceuticals. Having worked in reputed Indian and Non-Indian Multinational Organisations, the entrepreneurs are well armed with the rich experience of high class professional management well embedded in the principles of Good governance and values. The management of Splenz is almost obsessed with a compassionate approach towards the needs of the society in general and the stake holders like Doctors and Patients in particular. Splenz believes it has a responsibility towards the society that it belongs to and is truly going to live up to its ethos of “Specially for you…for your Splendid Health”"
          variant="body1"
          align="justify"
          sx={{ mr: 4, ml: 4, my: 2 }}
        />
        <SpText
          label="Company has focused on the development and marketing of Healthcare Products with the three core segments “Gastroenterology”, “Urology” & “Gynaecology” Nowadays, Splenz Pharmaceuticals rapidly increasing its product portfolio, is said to further expand & grow its presence in Pan India."
          variant="body1"
          align="justify"
          sx={{ mr: 4, ml: 4, my: 2 }}
        />
        <SpText
          label="Services offered by Splenz Pharmaceuticals Pvt. Ltd."
          variant="body1"
          align="justify"
          sx={{ mr: 4, ml: 4, my: 0 }}
        />
        <Box sx={{display: 'flex', textAlign: 'justify', ml: 4}}>
          <ul style={{ listStyleType: "disc" }}>
            <li>Product Range with Excellent Quality: Wide Range of Products.</li>
            <li>Monopoly Privileges: Pharma Franchise all over India.</li>
            <li>Marketing and Promotion: Inputs: Reminder card, visual aids etc.</li>
            <li>Advance Dispatch System: Same day product dispatch.</li>
            {/* <li>Other Features: Low investment and great returns on PCD Pharma.</li> */}
          </ul>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUsPageView;
