import { Box, Divider, Input, InputAdornment, Typography } from "@mui/material";
import SpText from "./common/sp-text";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import SearchIcon from "@mui/icons-material/Search";
import {
  COMPANY_CONTACT_EMAIL,
  COMPANY_MOBILE,
} from "../constants/company-details";
import { BLACK, F_BORDER, PINK, WHITE } from "../utils/colors";
import { headersMenu } from "../constants/header-menu";
import { startCase } from "lodash";
import CompanyDrawer from "./drawer";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CompanyBreadcrumbs from "./bread-crumbs";
import SpSocialMedia from "./common/sp-social-media";
import "../styles/text-style.css";
import CompanyLogo from "./common/company-logo";

const CompanyHeaders = () => {
  const [open, setOpen] = useState(false);
  const [isCompanyProfileActive, setIsCompanyProfileActive] = useState(false);
  const [isOurProductsActive, setIsOurProductsActive] = useState(false);

  const navigate = useNavigate();

  const setActiveFn = (name: string, value: boolean) => {
    if (name === "company profile") {
      setIsCompanyProfileActive(value);
      setIsOurProductsActive(false);
    } else if (name === "our products") {
      setIsOurProductsActive(value);
      setIsCompanyProfileActive(false);
    }
  };

  const menuListRender = () => {
    if (isCompanyProfileActive) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fff",
            width: 200,
            boxShadow: 10,
            position: "absolute",
            top: 143,
            zIndex: 100,
          }}
          onMouseEnter={() => setActiveFn("company profile", true)}
          onMouseLeave={() => setActiveFn("company profile", false)}
        >
          {headersMenu[1].children.map((item, index) => (
            <Box key={index}>
              <Box
                sx={{
                  display: "flex",
                  px: 1,
                  py: 1.5,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: F_BORDER,
                  },
                }}
                onClick={() => {
                  navigate(item.route);
                  setActiveFn("company profile", false);
                }}
              >
                {startCase(item.name)}
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      );
    } else if (isOurProductsActive) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fff",
            width: 200,
            boxShadow: 10,
            position: "absolute",
            top: 143,
            ml: 24,
            zIndex: 1000,
          }}
          onMouseEnter={() => setActiveFn("our products", true)}
          onMouseLeave={() => setActiveFn("our products", false)}
        >
          {headersMenu[2].children.map((item, index) => (
            <Box key={index}>
              <Box
                sx={{
                  display: "flex",
                  px: 1,
                  py: 1.5,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: F_BORDER,
                  },
                }}
                onClick={() => {
                  navigate(item.route);
                  setActiveFn("our products", false);
                }}
              >
                {startCase(item.name)}
              </Box>
              <Divider />
            </Box>
          ))}
        </Box>
      );
    } else {
      return null;
    }
  };

  const companyHeaderMenu = () => {
    let menuListRendered = false;

    return (
      <>
        <Box
          sx={{
            display: { xs: "none", sm: "none", lg: "flex", xl: "flex" },
            backgroundColor: F_BORDER,
            justifyContent: 'space-between',
            alignItems: "center",
          }}
        >
          <Box sx={{display : 'flex', flexDirection: 'row', ml: { xs: "5%", sm: "10%", lg: "6%", xl: "15%" }}}>
          {headersMenu.map((menuItem) =>
            menuItem.children.length ? (
              <Box
                key={menuItem.name}
                onMouseEnter={() => setActiveFn(menuItem.name, true)}
                onMouseLeave={() => setActiveFn(menuItem.name, false)}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <SpText
                  label={startCase(menuItem.name)}
                  variant="h6"
                  isBold
                  sx={{
                    cursor: "pointer",
                    color: PINK,
                    px: menuItem.name.length >= 9 ? 4 : 1.5,
                    py: 1.5,
                    "&:hover": {
                      color: WHITE,
                      backgroundColor: PINK
                    },
                    borderRight: "0.5px solid black",
                  }}
                />
                {!menuListRendered &&
                  (menuListRendered = true) &&
                  menuListRender()}
              </Box>
            ) : (
              <SpText
                key={menuItem.name}
                label={startCase(menuItem.name)}
                variant="h6"
                isBold
                sx={{
                  cursor: "pointer",
                  color: PINK,
                  px: menuItem.name.length >= 9 ? 4 : 1.5,
                  py: 1.5,
                  "&:hover": {
                    color: WHITE,
                    backgroundColor: PINK
                  },
                  borderRight: "0.5px solid black",
                }}
                onClick={() => navigate(menuItem.route)}
              />
            )
          )}
          </Box>
          <Box sx={{ display: "flex", px: 2 }}>
            <SpSocialMedia />
          </Box>
        </Box>
      </>
    );
  };

  const companyTopHeader = () => {
    return (
      <Box>
        <Box
          sx={{
            display: { xs: "none", sm: "none", lg: "flex", xl: "flex" },
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignContent: "space-evenly",
            py: 2,
          }}
        >
          <CompanyLogo />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MailIcon
                sx={{
                  mr: 0.5,
                  width: 16,
                  display: { xs: "none", sm: "none", lg: "flex", xl: "flex" },
                }}
              />
              <SpText
                label="E-mail :"
                variant="body2"
                isBold
                sx={{
                  mr: 1,
                  display: { xs: "none", sm: "none", lg: "none", xl: "flex" },
                }}
              />
              <Typography
                className="text-link"
                variant="body2"
                sx={{
                  mr: 2,
                  display: { xs: "none", sm: "none", lg: "flex", xl: "flex" },
                  color: BLACK,
                }}
                onClick={() =>
                  window.open(`mailto:${COMPANY_CONTACT_EMAIL}`, "_blank")
                }
              >
                {`${COMPANY_CONTACT_EMAIL}`}
              </Typography>
              <CallIcon
                sx={{
                  mr: 0.3,
                  width: 16,
                  display: { xs: "none", sm: "none", lg: "flex", xl: "flex" },
                }}
              />
              <SpText
                label="Call :"
                variant="body2"
                isBold
                sx={{
                  mr: 1,
                  display: { xs: "none", sm: "none", lg: "none", xl: "flex" },
                }}
              />
              <Typography
                className="text-link"
                variant="body2"
                sx={{
                  display: { xs: "none", sm: "none", lg: "flex", xl: "flex" },
                  color: BLACK,
                }}
                onClick={() =>
                  window.open(`tel:${COMPANY_MOBILE}`, "_blank")
                }
              >
                {`${COMPANY_MOBILE}`}
              </Typography>
            </Box>
            <Input
              id="standard-adornment-weight"
              placeholder="Search Product"
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
              aria-describedby="standard-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              sx={{
                display: { xs: "none", sm: "none", lg: "flex", xl: "flex" },
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "flex", sm: "flex", lg: "none", xl: "none" },
            py: 2,
          }}
        >
          <DehazeIcon
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              ml: 1,
            }}
            fontSize="large"
            onClick={() => setOpen(!open)}
          />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center'
            }}
          >
          <CompanyLogo />
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <>
      <CompanyDrawer isOpenClick={open} isClosed={() => setOpen(false)} />
      {companyTopHeader()}
      {companyHeaderMenu()}
      <CompanyBreadcrumbs />
    </>
  );
};

export default CompanyHeaders;
