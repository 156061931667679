import { Box, Divider, List, ListItem } from "@mui/material";
import SpText from "../../components/common/sp-text";
import { F_BORDER, PINK } from "../../utils/colors";
import WorldWideIcon from "../../assets/why-us/world_wide.png";
import ElightIcon from "../../assets/why-us/elight.png";
import CompetitivePricingIcon from "../../assets/why-us/competetive.png";
import ShortDeliveryIcon from "../../assets/why-us/short-delivery.png";
import ClientSatisfactionIcon from "../../assets/why-us/client-satification.png";

const WhyUsPageView = () => {
  const leftPanelView = () => {
    return (
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          px: 2,
        }}
      >
        <ListItem alignItems="flex-start" sx={{ my: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <img
              src={WorldWideIcon}
              alt="company-logo"
              width="35px"
              height="35px"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <SpText
                label="1250"
                variant="h6"
                sx={{ textAlign: "left", ml: 2 }}
              />
              <SpText
                label="The number of happy clients worldwide since we started"
                variant="body2"
                sx={{ textAlign: "left", ml: 2 }}
              />
            </Box>
          </Box>
        </ListItem>
        <Divider component="li" />
        <ListItem alignItems="flex-start" sx={{ my: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <img
              src={ElightIcon}
              alt="company-logo"
              width="35px"
              height="35px"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <SpText
                label="Eight"
                variant="h6"
                sx={{ textAlign: "left", ml: 2 }}
              />
              <SpText
                label="The number of national and international certification"
                variant="body2"
                sx={{ textAlign: "left", ml: 2 }}
              />
            </Box>
          </Box>
        </ListItem>
        <Divider component="li" />
        <ListItem alignItems="flex-start" sx={{ my: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <img
              src={CompetitivePricingIcon}
              alt="company-logo"
              width="35px"
              height="35px"
            />
            <SpText
              label="Competitive Pricing"
              variant="h6"
              sx={{ textAlign: "left", ml: 2 }}
            />
          </Box>
        </ListItem>
        <Divider component="li" />
        <ListItem alignItems="flex-start" sx={{ my: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <img
              src={ShortDeliveryIcon}
              alt="company-logo"
              width="35px"
              height="35px"
            />
            <SpText
              label="Short Delivery Lead Time"
              variant="h6"
              sx={{ textAlign: "left", ml: 2 }}
            />
          </Box>
        </ListItem>
        <Divider component="li" />
        <ListItem alignItems="flex-start" sx={{ my: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <img
              src={ClientSatisfactionIcon}
              alt="company-logo"
              width="35px"
              height="35px"
            />
            <SpText
              label="Client satisfaction guaranteed"
              variant="h6"
              sx={{ textAlign: "left", ml: 2 }}
            />
          </Box>
        </ListItem>
      </List>
    );
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", ml: 4, my: 5 }}>
        <SpText label="Why Choose Us?" variant="h5" isBold />
      </Box>
      <Box
        sx={{
          display: { xs: "flex", sm: "flex", lg: "flex", xl: "flex" },
          flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: { xs: "flex", sm: "flex", lg: "flex", xl: "flex" },
            border: `0.5px solid ${F_BORDER}`,
            mx: 4,
          }}
        >
          {leftPanelView()}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: { xs: 4, sm: 4 },
            mr: { xs: 4, sm: 4, lg: 4, xl: 4 },
            width: { lg: "100%", xl: "100%" },
            alignItems: "flex-start",
          }}
        >
          <SpText label="KEY STRENGTHS" variant="h5" isBold sx={{ mt: 2 }} />
          <Box sx={{ display: "flex", textAlign: "justify" }}>
            <ul style={{ listStyleType: "square" }}>
              <li style={{ marginTop: 2 }}>
                One of the oldest, most respected and trusted pharmaceutical
                companies in India
              </li>
              <li style={{ marginTop: 15 }}>
                Experienced and dedicated team for the manufacturing and supply
                of pharmaceuticals
              </li>
              <li style={{ marginTop: 15 }}>
                Diverse portfolio of products for the pharmaceuticals and
                healthcare sectors
              </li>
              <li style={{ marginTop: 15 }}>
                Strong customer base in India and across the globe
              </li>
              <li style={{ marginTop: 15 }}>
                Team of high-caliber professionals such as scientists,
                engineers, lab chemists, production heads, and functional
                managers
              </li>
              <li style={{ marginTop: 15 }}>
                Best standards of quality, affordability, and customer
                satisfaction
              </li>
              <li style={{ marginTop: 15 }}>
                Strict compliance with Good Manufacturing Practices (GMP)
              </li>
              <li style={{ marginTop: 15 }}>
                Driven by quality, integrity, and innovation & committed to
                business ethics
              </li>
              <li style={{ marginTop: 15 }}>
                Customized packaging as per the customer and market requirements
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WhyUsPageView;
