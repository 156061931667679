import { Box, Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { BLACK, BREADCRUMB, PINK, WHITE } from "../utils/colors";
import SpText from "./common/sp-text";
import CallIcon from "@mui/icons-material/Call";
import { COMPANY_MOBILE } from "../constants/company-details";
import { replace, startCase } from "lodash";

const CompanyBreadcrumbs = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathnames = location.pathname.split("/").filter((x) => x)

  const smallScreenHeaders = () => {
    return (
      <Box
        sx={{
          display: { xs: "flex", sm: "none", lg: "none", xl: "none" },
          backgroundColor: BLACK,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
            onClick={() => window.open(`tel:${COMPANY_MOBILE}`, "_blank")}
          >
            <CallIcon fontSize="large" sx={{ color: WHITE, mt: 1.8, mx: 1 }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "flex-start",
                alignContent: "space-around",
              }}
            >
              <SpText
                label="Call us on"
                variant="subtitle1"
                sx={{ color: WHITE, mb: -1, mt: 1 }}
              />
              <Typography
                className="text-link"
                variant="subtitle1"
                fontWeight={"bold"}
                sx={{
                  color: WHITE,
                }}
                onClick={() => window.open(`tel:${COMPANY_MOBILE}`, "_blank")}
              >
                {`${COMPANY_MOBILE}`}
              </Typography>
            </Box>
          </Box>
          <SpText
            label="Get a Quote"
            variant="h6"
            isBold={true}
            sx={{
              backgroundColor: PINK,
              color: WHITE,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              px: 1.5,
              cursor: "pointer",
            }}
            onClick={() => navigate("/contact-us")}
          />
        </Box>
      </Box>
    );
  };

  const generateBreadcrumbs = () => {
    if (pathnames.length) {
      return (
        <Box
          sx={{
            display: { xs: "none", sm: "flex", lg: "flex", xl: "flex" },
            backgroundColor: BREADCRUMB,
          }}
        >
          <Box sx={{ marginLeft: 3, my: 2 }}>
            <Stack spacing={2}>
              <Breadcrumbs separator="/" aria-label="breadcrumb">
                <Box sx={{ display: "flex" }}>
                  <HomeIcon sx={{ mr: 1 }} />
                  <Link href="/" color="inherit" underline="hover">
                    Home
                  </Link>
                </Box>
                {pathnames.length > 1 ? (
                  <Link
                    href={`/${pathnames[0]}`}
                    color="inherit"
                    underline="hover"
                  >
                    {startCase(replace(pathnames[0].charAt(0).toUpperCase() +
                      pathnames[0].slice(1), '-', ' '))}
                  </Link>
                ) : (
                  <Typography key="3" color="text.primary">
                    {startCase(replace(pathnames[0].charAt(0).toUpperCase() +
                      pathnames[0].slice(1), '-', ' '))}
                  </Typography>
                )}
                {pathnames.length > 1 && (
                  <Typography key="3" color="text.primary">
                    {startCase(replace(pathnames[1].charAt(0).toUpperCase() +
                      pathnames[1].slice(1), '-', ' '))}
                  </Typography>
                )}
              </Breadcrumbs>
            </Stack>
          </Box>
        </Box>
      );
    }
  };

  return (
    <>
      {pathnames[0] !== "home" ? generateBreadcrumbs() : null}
      {smallScreenHeaders()}
    </>
  );
};

export default CompanyBreadcrumbs;
