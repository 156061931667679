import { Box, Divider, List, ListItem, Typography } from "@mui/material";
import { BLACK, F_BLACK, PINK, SKY_BLUE, WHITE } from "../utils/colors";
import LocationFooter from "../assets/footer/location_foo.png";
import CallFooter from "../assets/footer/call_foo.png";
import MailFooter from "../assets/footer/mail_foo.png";
import SpText from "./common/sp-text";
import {
  COMPANY_ALTERNATE_MOBILE,
  COMPANY_CONTACT_EMAIL,
  COMPANY_CONTACT_EMAIL_LINK,
  COMPANY_COPYRIGHT,
  COMPANY_HEADQUARTER_ADDRESS,
  COMPANY_HEADQUARTER_ADDRESS_LINK,
  COMPANY_MOBILE,
  COMPANY_SERVICE_EMAIL,
} from "../constants/company-details";
import "../styles/footer.css";
import "../styles/text-style.css";
import SpSocialMedia from "./common/sp-social-media";
import { snakeCase, startCase } from "lodash";
import { useNavigate } from "react-router-dom";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { gastroenterologyProductsDetails } from "../constants/products/gastroenterology";
import { urologyProductsDetails } from "../constants/products/urology";
import Certificate_1 from "../assets/global-certifications/certification_1.png";
import Certificate_2 from "../assets/global-certifications/certification_2.png";
import Certificate_3 from "../assets/global-certifications/certification_3.png";
import Certificate_4 from "../assets/global-certifications/certification_4.png";
import Certificate_5 from "../assets/global-certifications/certification_5.png";
import Certificate_6 from "../assets/global-certifications/certification_6.png";

const footerActions = [
  {
    name: "home",
    route: "/",
  },
  {
    name: "about us",
    route: "/about-us",
  },
  {
    name: "why us",
    route: "/why-us",
  },
  {
    name: "mission vision",
    route: "/mission-vision",
  },
  {
    name: "gallery",
    route: "/gallery",
  },
  {
    name: "brand",
    route: "/our-brands",
  },
  {
    name: "contact us",
    route: "/contact-us",
  },
];

const CompanyFooters = () => {
  const navigate = useNavigate();
  const renderAddPhoneMail = (
    header: string,
    icon: any,
    textOne: string,
    textTwo?: string,
    link?: string
  ) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "space-between",
          backgroundColor: SKY_BLUE,
          width: "100%",
          mx: 2,
          my: 1.3,
          borderRadius: 3,
          py: 1.5,
          pb: 3.5,
          px: 2,
          height: "60px",
          cursor: link?.length ? "pointer" : "",
        }}
        onClick={() => {
          window.open(link, "_blank", "noopener, noreferrer");
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            alignContent: "space-around",
            alignItems: "flex-start",
            ml: 2,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <SpText
              label="|"
              variant="h4"
              sx={{ mr: 0.8, mt: -0.5, color: PINK }}
            />
            <SpText label={header} variant="h6" isBold />
          </Box>
          <SpText label={textOne} variant="subtitle2" />
          {textTwo && <SpText label={textTwo} variant="subtitle2" />}
        </Box>
        <img src={icon} alt="company-logo" width={60} />
      </Box>
    );
  };

  const renderBottomView = () => {
    return (
      <Box
        sx={{
          display: { xs: "none", sm: "flex", lg: "flex", xl: "flex" },
          justifyContent: "center",
          gap: 1,
          cursor: "pointer",
          mt: 1,
        }}
      >
        {footerActions.map((action, index) => (
          <Box key={index} sx={{display: 'flex', gap: 1}}>
            {action.name !== "home" && (
              <Typography variant="h6" sx={{ color: WHITE }}>
                |
              </Typography>
            )}
            <Typography
              className="text-link"
              variant="body2"
              sx={{ mt: 0.7 }}
              onClick={() => navigate(action.route)}
            >
              {startCase(action.name)}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  const renderGetInTouch = () => {
    return (
      <List
        sx={{
          width: "100%",
        }}
      >
        <ListItem alignItems="flex-start">
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LocationOnIcon
              fontSize="medium"
              sx={{
                backgroundColor: PINK,
                borderRadius: "50%",
                padding: "5px",
              }}
            />
            <SpText
              label={COMPANY_HEADQUARTER_ADDRESS}
              variant="body2"
              sx={{ textAlign: "left", ml: 2 }}
            />
          </Box>
        </ListItem>
        <Divider component="li" />
        <ListItem alignItems="flex-start">
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <PhoneIphoneIcon
              fontSize="medium"
              sx={{
                backgroundColor: PINK,
                borderRadius: "50%",
                padding: "5px",
              }}
            />
            <Box>
              {renderTitleAndValueView("Mobile:", COMPANY_MOBILE, `tel:${COMPANY_MOBILE}`)}
              {renderTitleAndValueView("Phone:", COMPANY_ALTERNATE_MOBILE, `tel:${COMPANY_ALTERNATE_MOBILE}`)}
            </Box>
          </Box>
        </ListItem>
        <Divider component="li" />
        <ListItem alignItems="flex-start">
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <MailIcon
              fontSize="medium"
              sx={{
                backgroundColor: PINK,
                borderRadius: "50%",
                padding: "5px",
              }}
            />
            <Box>
            <SpText
                label={`Email:`}
                variant="body2"
                sx={{ textAlign: "left", ml: 2 }}
              />
              <Typography
                className="text-link"
                variant="body2"
                sx={{ textAlign: "left", ml: 2 }}
                onClick={() =>
                  window.open(`mailto:${COMPANY_CONTACT_EMAIL}`, "_blank")
                }
              >
                {`${COMPANY_CONTACT_EMAIL}`}
              </Typography>
              <Typography
                className="text-link"
                variant="body2"
                sx={{ textAlign: "left", ml: 2 }}
                onClick={() =>
                  window.open(`mailto:${COMPANY_SERVICE_EMAIL}`, "_blank")
                }
              >
                {`${COMPANY_SERVICE_EMAIL}`}
              </Typography>
            </Box>
          </Box>
        </ListItem>
      </List>
    );
  };

  const renderTitleAndValueView = (title: string, value: string, url: string) => {
    return (
      <Box display={"flex"}>
        <SpText
          label={title}
          variant="body2"
          sx={{ textAlign: "left", ml: 2 }}
        />
        <Typography
          className="text-link"
          variant="body2"
          sx={{ textAlign: "left", ml: 1 }}
          onClick={() => (url ? window.open(url, "_blank") : null)}
        >
          {`${value}`}
        </Typography>
      </Box>
    );
  }

  const renderProductsListView = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            ml: 5,
          }}
        >
          <SpText
            label="Gastroenterology"
            variant="subtitle1"
            isBold
            sx={{ color: PINK }}
          />
          <List
            sx={{
              width: "250px",
            }}
          >
            <ListItem alignItems="flex-start">
              <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
                {gastroenterologyProductsDetails.map((productDetail, index) => (
                  <Box key={index} sx={{ display: "flex", flexDirection: "row" }}>
                    <SpText label=">" variant="subtitle2" sx={{ mr: 1 }} />
                    <Typography
                      className="text-link"
                      variant="subtitle2"
                      onClick={() =>
                        navigate(
                          `./gastroenterology/${snakeCase(
                            productDetail.name
                          )}?id=${productDetail.id}`
                        )
                      }
                    >
                      {productDetail.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </ListItem>
          </List>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            ml: { xs: 5, sm: 5, lg: 0, xl: 0 },
          }}
        >
          <SpText
            label="Urology"
            variant="subtitle1"
            isBold
            sx={{ color: PINK }}
          />
          <List
            sx={{
              width: "250px",
            }}
          >
            <ListItem alignItems="flex-start">
              <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
                {urologyProductsDetails.map((productDetail, index) => (
                  <Box key={index} sx={{ display: "flex", flexDirection: "row" }}>
                    <SpText label=">" variant="subtitle2" sx={{ mr: 1 }} />
                    <Typography
                      className="text-link"
                      variant="subtitle2"
                      onClick={() =>
                        navigate(
                          `./urology/${snakeCase(productDetail.name)}?id=${
                            productDetail.id
                          }`
                        )
                      }
                    >
                      {productDetail.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </ListItem>
          </List>
        </Box>
      </Box>
    );
  };

  const renderGlobalCertificationView = () => {
    return (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: {
            xs: "column",
            sm: "row",
            lg: "column",
            xl: "column",
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <img src={Certificate_1} alt="company-logo" width={120} />
          <img src={Certificate_2} alt="company-logo" width={120} />
          <img src={Certificate_3} alt="company-logo" width={120} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <img src={Certificate_4} alt="company-logo" width={120} />
          <img src={Certificate_5} alt="company-logo" width={120} />
          <img src={Certificate_6} alt="company-logo" width={120} />
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: F_BLACK,
          color: WHITE,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
            mx: { xs: 0, sm: 0, lg: "5%", xl: "5%" },
          }}
        >
          <Box sx={{ display: "flex", width: "100%" }}>
            {renderAddPhoneMail(
              "Address",
              LocationFooter,
              "Find Us On Map",
              "",
              COMPANY_HEADQUARTER_ADDRESS_LINK
            )}
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            {renderAddPhoneMail(
              "Phone",
              CallFooter,
              COMPANY_MOBILE,
              COMPANY_ALTERNATE_MOBILE,
              `tel:${COMPANY_MOBILE}`
            )}
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            {renderAddPhoneMail(
              "Email",
              MailFooter,
              COMPANY_CONTACT_EMAIL,
              "",
              COMPANY_CONTACT_EMAIL_LINK
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "column", lg: "row", xl: "row" },
            justifyContent: "center",
            gap: 2,
            mb: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", sm: "100%", lg: "20%", xl: "33%" },
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", ml: 2, my: 4 }}>
              <SpText label="|" variant="h4" sx={{ mr: 1, color: PINK }} />
              <SpText
                label="Get in touch"
                variant="h6"
                isBold
                sx={{ mr: 0.6, mt: 0.5 }}
              />
            </Box>
            {renderGetInTouch()}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "row", ml: 2, my: 4 }}>
              <SpText label="|" variant="h4" sx={{ mr: 1, color: PINK }} />
              <SpText
                label="Splenz Products"
                variant="h6"
                isBold
                sx={{ mr: 0.6, mt: 0.5 }}
              />
            </Box>
            {renderProductsListView()}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mx: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "row", my: 4 }}>
              <SpText label="|" variant="h4" sx={{ mr: 1, color: PINK }} />
              <SpText
                label="Global Certifications"
                variant="h6"
                isBold
                sx={{ mr: 0.6, mt: 0.5 }}
              />
            </Box>
            {renderGlobalCertificationView()}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: BLACK,
        }}
      >
        {renderBottomView()}
        <SpText
          label={COMPANY_COPYRIGHT}
          variant="body1"
          sx={{ color: WHITE, px: 1, mt: { xs: 1, sm: 0, lg: 0, xl: 0 } }}
        />
        <SpSocialMedia />
      </Box>
    </>
  );
};

export default CompanyFooters;
